import axios, { AxiosPromise } from 'axios';
import { EVENT_SERVICE_ROOT } from './api-consts';
import {
  BasketTypes,
  ProductStatuses,
} from '../product-schema/pdw-select-options';
import { toScreamingSnakeCase } from '../utilities/utilities';

export enum ConfirmationMethods {
  MANUAL = 'MANUAL',
  ISSUER_API = 'ISSUER_API',
}
export enum LikelihoodOfCallOptions {
  LIKELY = 'Likely',
  NEUTRAL = 'Neutral',
  UNLIKELY = 'Unlikely',
}

export enum EventStatusEnum {
  CALCULATED = 'Calculated',
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  NOT_APPLICABLE = 'Not Applicable',
}

export enum EventTypes {
  AUTO_CALL = 'Auto Call',
  ISSUER_CALL = 'Issuer Call',
  MISSED_CALL = 'Missed Call',
  COUPON_PAYMENT = 'Coupon Payment',
  MISSED_COUPON_PAYMENT = 'Missed Coupon Payment',
  BARRIER_BREACH = 'Barrier Breach',
  MATURITY = 'Maturity',
  PDW_COUPON_DATE = 'PDW Coupon Date',
  PDW_CALL_DATE = 'PDW Call Date',
  PDW_TRADE_DATE = 'PDW Trade Date',
  PDW_ISSUED_DATE = 'PDW Issued Date',
  PDW_FINAL_VALUATION_DATE = 'PDW Final Valuation Date',
  PDW_MATURITY_DATE = 'PDW Maturity Date',
  PDW_STRIKE_DATE = 'PDW Strike Date',
}

export enum ConfirmationStatus {
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  REQUIRES_RESOLUTION = 'Requires Resolution',
  NOT_CONFIRMED = 'Not Confirmed',
  NOT_SET = '',
}

export type LastSentEmail = {
  executedByUser: string;
  executedTimestamp: string;
  templateKey: string;
  companyKey: string;
};
export type MarkToMarketData = {
  productIdentifier: string;
  primaryActivity: number;
  tradeDate: string;
  source: string;
};

export enum DownsideType {
  BUFFER = 'Buffer',
  BARRIER = 'Barrier',
  FULL = 'Full',
  PARTIAL = 'Partial',
  BEARISH = 'Bearish',
  NO_PRINCIPAL_PROTECTION = 'No Principal Protection',
  AIRBAG = 'Airbag',
  GEARED_BARRIER = 'Geared Barrier',
  GEARED_BUFFER = 'Geared Buffer',
}

export enum PhysicalSettlementType {
  SHARES = 'Shares',
  CASH_IN_LIEU = 'Cash in Lieu',
}

export type SecurityIdType = 'RIC' | 'Bloomberg' | 'ISIN' | 'CUSIP';

export type PhysicalSettlement = {
  issuerValue?: number;
  lumaValue?: number;
  physicalSettlementType?: PhysicalSettlementType;
  recordId?: number;
  securityId?: string;
  securityIdType?: SecurityIdType;
  value?: number;
};

export const emptyPhysicalSettlement: PhysicalSettlement = {
  issuerValue: undefined,
  lumaValue: undefined,
  physicalSettlementType: undefined,
  recordId: undefined,
  securityId: undefined,
  securityIdType: undefined,
  value: undefined,
};

export const emptyPhysicalSettlementList: PhysicalSettlement[] = [
  {
    ...emptyPhysicalSettlement,
    physicalSettlementType: PhysicalSettlementType.SHARES,
  },
  {
    ...emptyPhysicalSettlement,
    physicalSettlementType: PhysicalSettlementType.CASH_IN_LIEU,
  },
];

export interface EventCoreRecord {
  source: any | string;
  sourceType: any | string;
  sourceId: any | string;
  productId: any | string;
  productIdType: any | string;
  settlementType: any | string;
  eventType: EventTypes | null;
  couponPaymentType: any | null;
  eventStatus: EventStatusEnum | null;
  eventDate: any | string;
  rate: any | number;
  lumaRate: any | null;
  issuerRate: any | null;
  issuerRateLastModifiedBy: any | null;
  issuerRateLastModifiedTime: any | null;
  lumaRateLastModifiedBy: any | null;
  lumaRateLastModifiedTime: any | null;
  confirmedRateLastModifiedBy: any | null;
  confirmedRateLastModifiedTime: any | null;
  confirmationMethod: ConfirmationMethods;
  physicalSettlementItems: any | [];
  settlementDate: any | string;
  matchingRedemptionObservation: any | true;
  user: any | string;
  creationDate: any | string;
  lastModified: any | string;
  matchingCallEvent: any | null;
  matchingCouponEvent: any | null;
  externalReferenceId: any | null;
  likelihoodOfCall: LikelihoodOfCallOptions;
}

export enum SettlementTypeOptions {
  CASH = 'Cash',
  PHYSICAL = 'Physical',
}

export interface CashSettlementRates {
  rate: number | null | undefined | '';
  issuerRate: number | null | undefined | '';
  lumaRate: number | null | undefined | '';
}

export interface UnderlierList {
  ticker?: string;
  type?: SecurityIdType;
  name?: string;
  strikePrice?: number;
  currentPrice?: number;
  assetType?: string;
}

export type EventConfirmationGeneralParams = {
  escalationOverride?: boolean;
  requestBy: EventConfirmationRequestBy;
};

export enum EventConfirmationRequestBy {
  LUMA = 'LUMA',
  ISSUER = 'ISSUER',
  CALC_AGENT = 'CALC_AGENT',
}

export type EventConfirmationGeneral = {
  eventIdentifier: {
    productIdentifier: string;
    eventDate: string | null | undefined;
    eventType: string;
  };
  cashSettlementRates?: {
    rate?: string;
    lumaRate?: string;
    issuerRate?: string;
  };
  settlementType: 'Cash' | 'Physical';
  missed: boolean;
};

export type EventConfirmation = {
  productIdentifier?: string;
  cusip?: string;
  isin?: string;
  eventId: string;
  productSymbol?: any;
  eventType?: EventTypes;
  eventDate?: string;
  confirmationStatus?: ConfirmationStatus;
  settlementDate?: string;
  settlementType?: SettlementTypeOptions;
  cashSettlementRates?: CashSettlementRates;
  physicalSettlements?: PhysicalSettlement[];
  productStatus?: ProductStatuses;
  originator?: any;
  issuer?: string;
  structureNameInternal?: string;
  structureShortNameInternal?: string;
  basketType?: BasketTypes;
  principalProximityDistance?: number;
  principalProximityThreshold?: number;
  activeUnderlier?: string;
  underlierList?: UnderlierList[];
  registrationType?: string;
  tradeDate?: string;
  strikeDate?: string;
  tenorFinal?: number;
  tenorUnit?: string;
  underlierPerformance?: number;
  downsideType?: DownsideType;
  productMarkToMarketData?: MarkToMarketData;
  issueSize?: number;
  paymentFrequency?: any;
  executed_by_user?: any;
  executed_ts?: any;
  lastSentEmails?: LastSentEmail[];
  likelihoodOfCall?: any;
  issuerRateLastModifiedBy?: any;
  issuerRateLastModifiedTime?: any;
  lumaRateLastModifiedBy?: any;
  lumaRateLastModifiedTime?: any;
  confirmedRateLastModifiedBy?: any;
  confirmedRateLastModifiedTime?: any;
  confirmationMethod?: ConfirmationMethods;
  holdingClients?: string[];
  issuerNote?: any;
  issuerMarkedAsMissed?: boolean;
  lumaMarkedAsMissed?: boolean;
  lumaAuditNote?: any;
  lumaIssuerAuditNote?: any;
  escalationRequired?: boolean;
  markToMarketValue?: number;
  initialLumaRate?: any;
  formattedLumaOutcome?: string;
  fixedCoupon?: boolean;
  rateLinked?: boolean;
  ACAT?: any;
  markToMarketDifference?: any;
  initialLumaRateDifference?: any;
};

export type EventConfirmationGridData = EventConfirmation & {
  modifiedInThisSession?: boolean;
};

export enum IssuerConfirmationStatus {
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  MISSED = 'Missed',
  IGNORED = 'Ignored',
}

// export type IssuerConfirmationTypes =
//   | 'AUTO_CALL'
//   | 'ISSUER_CALL'
//   | 'COUPON_PAYMENT'
//   | 'MATURITY';
//
export enum IssuerConfirmationStatusRow {
  CALCULATED = 'CALCULATED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  REQUIRES_RESOLUTION = 'REQUIRES_RESOLUTION',
  IGNORED = 'IGNORED',
}

export type IssuerConfirmation = {
  id: number; // 786742,
  productId: string; // "XS2636388089",
  productIdType: string; // "ISIN",
  registrationType: string; // "Reg S",
  confirmationType: EventTypes; // "Coupon Payment",
  issuer: string; // "JPMorgan Chase Structured Products B.V.",
  observationDate: string; // "2023-11-08",
  settlementDate: string; // "2023-11-15",
  settlementType: any | null; // null,
  rate: number | null; // null,
  statusChangeDateTime: string; // "2024-02-23T12:44:09.485381Z",
  underliers: any | null; // null,
  doGenerateRecord: boolean; // false,
  doGenerateEmail: boolean; // false,
  user: string; // "ops@lumafintech.com",
  issuerNote: string | null; // null,
  issuerMarkedAsMissed: boolean; // false,
  lumaMarkedAsMissed: boolean; // false,
  issuerMarkedAsMissedDateTime: any | null; // null,
  issuerMarkedAsMissedBy: any | null; // null,
  lumaMarkedAsMissedDateTime: any | null; // null,
  lumaMarkedAsMissedBy: any | null; // null,
  confirmedMarkedAsMissedDateTime: any | null; // null,
  confirmedMarkedAsMissedBy: any | null; // null,
  status: IssuerConfirmationStatus; // "Pending"
};

export const getEventsByProductId = (productId: string) => {
  return axios.get<EventCoreRecord[]>(`${EVENT_SERVICE_ROOT}/events/core`, {
    params: { productId: encodeURIComponent(productId) },
  });
};

export const getEventConfirmationsForDateRange = ({
  startDate,
  endDate,
}: {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}) => {
  if (!startDate || !endDate) {
    return Promise.reject('Missing Dates');
  }
  return axios.get<EventConfirmation[]>(
    `${EVENT_SERVICE_ROOT}/event-confirmations`,
    { params: { startDate, endDate } },
  );
};

export const getEventConfirmationsById = (cusipOrIsins: string[]) => {
  return axios.post<EventConfirmation[]>(
    `${EVENT_SERVICE_ROOT}/event-confirmations/confirmationsById`,
    cusipOrIsins,
  );
};

// export const

export type PutEventConfirmationRequest = {
  event: EventConfirmation;
  requestBy: EventConfirmationRequestBy;
  escalationOverride?: boolean;
};
export const putEventConfirmation = (payload: PutEventConfirmationRequest) => {
  return axios.put(`${EVENT_SERVICE_ROOT}/event-confirmations`, payload.event, {
    params: {
      requestBy: payload.requestBy,
      markedAsMissedByIssuer: payload.event.issuerMarkedAsMissed || false,
      escalationOverride: payload.escalationOverride || false,
    },
  });
};

export type GetIssuerConfirmationRequest = {
  cusipOrIsin: string;
  confirmationTypes: EventTypes;
  observationDate: string;
};

export type PageableResponse<T> = {
  content: T[];
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  last: boolean;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  empty: boolean;
};

export const getIssuerConfirmation = (
  payload: GetIssuerConfirmationRequest,
) => {
  return axios.get<IssuerConfirmation[]>(
    `${EVENT_SERVICE_ROOT}/issuer-confirmations/unique-id`,
    // payload,
    {
      params: {
        productIdentifier: payload.cusipOrIsin,
        confirmationTypes: toScreamingSnakeCase(payload.confirmationTypes),
        observationDate: payload.observationDate,
      },
    },
  );
};

export type GetEventCoreRecordRequest = {
  eventTypes: EventTypes;
  observationDate: string;
  cusipOrIsin: string;
};

export const getEventCoreRecord = (
  payload: GetEventCoreRecordRequest,
): AxiosPromise<PageableResponse<EventCoreRecord>> => {
  return axios.post(
    `${EVENT_SERVICE_ROOT}/events/core`,
    [payload.cusipOrIsin],
    {
      params: {
        eventTypes: toScreamingSnakeCase(payload.eventTypes),
        startDate: payload.observationDate,
        endDate: payload.observationDate,
        page: 0,
      },
    },
  );
};

export type DeleteEventCoreRecordRequest = {
  sourceId: string;
  source: string;
};

export const deleteEventCoreRecord = (
  payload: DeleteEventCoreRecordRequest,
) => {
  return axios.delete(`${EVENT_SERVICE_ROOT}/events/core/${payload.sourceId}`, {
    params: {
      source: payload.source,
    },
  });
};

export const putIssuerConfirmation = (payload: IssuerConfirmation) => {
  return axios.put(`${EVENT_SERVICE_ROOT}/issuer-confirmations`, payload);
};

interface IGetEventConfirmationsAuditLogPayload {
  productIdentifier: string;
  eventDate: string;
  eventType: string;
}

export enum EventTypeDisplay {
  AUTO_CALL = 'Auto Call',
  ISSUER_CALL = 'Issuer Call',
  MATURITY = 'Maturity',
  COUPON = 'Coupon Payment',
}

export interface EventCoreRow {
  source: string;
  source_type: 'LUMA' | 'ISSUER' | 'DTC' | 'EC' | 'THIRD_PARTY';
  source_id: string;
  product_id: string;
  product_id_type: 'CUSIP' | 'ISIN';
  settlement_type: 'PHYSICAL' | 'CASH';
  event_type:
    | 'AUTO_CALL'
    | 'ISSUER_CALL'
    | 'MISSED_CALL'
    | 'COUPON_PAYMENT'
    | 'MISSED_COUPON_PAYMENT'
    | 'BARRIER_BREACH'
    | 'MATURITY'
    | 'PDW_COUPON_DATE'
    | 'PDW_CALL_DATE'
    | 'PDW_TRADE_DATE'
    | 'PDW_ISSUED_DATE'
    | 'PDW_FINAL_VALUATION_DATE'
    | 'PDW_MATURITY_DATE'
    | 'PDW_STRIKE_DATE';
  coupon_payment_type: string | null;
  event_status:
    | 'CALCULATED'
    | 'PENDING'
    | 'CONFIRMED'
    | 'NOT_CONFIRMED'
    | 'REQUIRES_RESOLUTION';
  event_date: string;
  rate: number | null;
  luma_rate: number | null;
  luma_rate_last_modified_by: string | null;
  luma_rate_last_modified_time: string | null;
  issuer_rate: number | null;
  issuer_rate_last_modified_by: string | null;
  issuer_rate_last_modified_time: string | null;
  settlement_date: string;
  matching_redemption_observation: boolean;
  user: string;
  creation_date: string;
  last_modified: string;
  matching_redemption_id: string | null;
  matching_redemption_source: string | null;
  external_reference_id: string | null;
  confirmation_date: string | null;
  luma_audit_note: string | null;
  luma_issuer_audit_note: string | null;
}

export interface PhysicalSettlementRow {
  record_id: number;
  event_source: string;
  event_id: string;
  physical_settlement_type: 'SHARES' | 'CASH_IN_LIEU';
  security_id: string;
  security_id_type: string;
  value: null | number;
  issuer_value: null | number;
  luma_value: null | number;
}

export interface IssuerConfirmationRow {
  id: number;
  product_id: string;
  product_id_type: 'ISIN' | 'CUSIP';
  registration_type: string;
  confirmation_type: string;
  issuer: string;
  issuer_note: string | null;
  observation_date: string;
  settlement_date: string;
  rate: null | number;
  status: IssuerConfirmationStatusRow;
  status_change_datetime: string;
  user: null | string;
  issuer_marked_as_missed: boolean;
  issuer_marked_as_missed_datetime: string | null;
  issuer_marked_as_missed_by: string | null;
  luma_marked_as_missed: boolean;
  luma_marked_as_missed_datetime: string | null;
  luma_marked_as_missed_by: string | null;
}

export type EventEmailRow = {
  attachedDocumentKeys?: string[];
  bcc?: string[];
  cc?: string[];
  companyKey?: string;
  documentVersionKeys?: string[];
  emailComponent?:
    | 'LIFECYCLE_MANAGEMENT'
    | 'PRODUCT_MARKETPLACE'
    | 'CREATION_HUB'
    | 'AUTH'
    | 'OTHER';
  from?: string;
  model?: {
    is3a2: boolean;
    isReg: boolean;
    isRegS: boolean;
    subHeader: string;
    date: string;
    productStructure: string;
    disclaimer: string;
    productIdentifier: string;
    table: { label: string; value: string }[];
  };
  postEmailSentQueue?: string;
  priority?: number;
  replyToAddress?: string;
  requireEnvInSubject?: boolean;
  subject?: string;
  templateKey?: string;
  to?: string[];
  triggerMethod?: 'AUTO' | 'MANUAL' | 'OTHER';
  triggeredBy?: string;
};

export enum OperationSource {
  EVENT_CORE = 'event_core',
  PHYSICAL_SETTLEMENT_CORE = 'physical_settlement_core',
  ISSUER_CONFIRMATION = 'issuer_confirmation',
  EVENT_CONFIRMATIONS_EMAIL = 'event-confirmations/email',
}

export interface AuditLogComponent {
  eventType: EventTypeDisplay;
  eventDate: string;
  productIdentifier: string;
  operationSource: OperationSource;
  executedByUser: null | string;
  operation: null | 'UPDATE' | 'INSERT' | string;
  oldRow:
    | null
    | EventCoreRow
    | PhysicalSettlementRow
    | IssuerConfirmationRow
    | EventEmailRow;
  newRow:
    | null
    | EventCoreRow
    | PhysicalSettlementRow
    | IssuerConfirmationRow
    | EventEmailRow;
  executedTimestamp: string;
}

export const getEventConfirmationsAuditLogKey = (
  filters: IGetEventConfirmationsAuditLogPayload,
) => ['eventConfirmations', 'auditLog', filters];

export const getEventConfirmationsAuditLog = (
  payload: IGetEventConfirmationsAuditLogPayload,
) => {
  return axios.get<AuditLogComponent[]>(
    `${EVENT_SERVICE_ROOT}/event-confirmations/audit-log`,
    {
      params: {
        productIdentifier: payload.productIdentifier,
        eventDate: payload.eventDate,
        eventType: payload.eventType,
      },
    },
  );
};

export const updateEventsGeneral = (
  payload: EventConfirmationGeneral[] | undefined,
  params?: EventConfirmationGeneralParams,
) => {
  if ((payload?.length || 0) === 0) {
    return Promise.reject('No events to update');
  }
  return axios.post(
    `${EVENT_SERVICE_ROOT}/event-confirmations/update`,
    payload,
    {
      params,
    },
  );
};
